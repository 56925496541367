import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.businessManagementAudit,
    component: () => import('../../pages/businessManagement/businessAudit'),
    meta: { title: '入驻审核', code: EAuthCode.BUSINESS_SETTLEDADUIT }
  },
  {
    path: routeNames.businessManagementAuditDetail,
    component: () => import('../../pages/businessManagement/businessAuditDetail'),
    meta: { title: '审核详情', hideInMenu: true, code:[ EAuthCode.BUSINESS_SETTLEDADUIT_AUDIT,EAuthCode.BUSINESS_SETTLEDADUIT_REPULSE,EAuthCode.BUSINESS_SETTLEDADUIT_AGAINREPULSE,EAuthCode.BUSINESS_SETTLEDADUIT_AGAINAUDIT]}
  },
  {
    path: routeNames.businessManagementList,
    component: () => import('../../pages/businessManagement/businessList'),
    meta: { title: '店铺列表', code: EAuthCode.BUSINESS_SHOPS }
  },
  {
    path: routeNames.businessManagementListDetail,
    component: () => import('../../pages/businessManagement/businessDetail'),
    meta: { title: '店铺详情', hideInMenu: true, code: EAuthCode.BUSINESS_SHOPS_DETAIL  }
  },
  {
    path: routeNames.businessManagementAccount,
    component: () => import('../../pages/businessManagement/businessAccount'),
    meta: { title: '商家账户管理', code: EAuthCode.BUSINESS_ACCOUNTS }
  },
  {
    path: routeNames.businessManagementType,
    component: () => import('../../pages/businessManagement/businessType'),
    meta: { title: '店铺类型设置', code: EAuthCode.BUSINESS_SHOPTYPESETTING }
  },
  {
    path: routeNames.businessManagementEarnest,
    component: () => import('../../pages/businessManagement/businessEarnest'),
    meta: { title: '保证金管理', code: EAuthCode.BUSINESS_DEPOSITMANAGE }
  },
  {
    path: routeNames.businessManagementNotify,
    component: () => import('../../pages/businessManagement/businessNotify'),
    meta: { title: '通知管理', code: EAuthCode.BUSINESS_NOTICEMANAGE }
  },
  {
    path: routeNames.advertisingNotifyAdd,
    component: () => import('../../pages/businessManagement/businessNotify/add'),
    meta: { title: '新增通知', hideInMenu: true, code: EAuthCode.BUSINESS_NOTICEMANAGE_ADD }
  },

  {
    path: routeNames.businessManagementClose,
    component: () => import('../../pages/businessManagement/businessClose'),
    meta: { title: '关店审核', code: EAuthCode.BUSINESS_CLOSESHOPAUDIT }
  },
  {
    path: routeNames.businessManagementCloseDetail,
    component: () => import('../../pages/businessManagement/businessCloseDetail'),
    meta: { title: '审核详情', hideInMenu: true , code: EAuthCode.BUSINESS_CLOSESHOPAUDIT_AUDIT }
  },
  {
    path: routeNames.businessManagementPlus,
    component: () => import('../../pages/businessManagement/businessPlus'),
    meta: { title: 'b+亮证特权', code: EAuthCode.BUSINESS_BPLUSSHOWCERTIFICATEPRIVILEGE }
  }
] as TRoutes[]
import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'
import { EAuthCode } from '~/enums/EAuthCode'

const routes: TRoutes[] = [
  {
    path: routeNames.dataAnalysisDashboard,
    component: () => import('~/pages/dataAnalysis/dashboard'),
    meta: { title: '数据仪表' /* , code: EAuthCode.DATAANALYSIS_DATAOVERVIEW */ }
  },
  {
    path: routeNames.dataOverview,
    component: () => import('~/pages/dataAnalysis/dataOverview'),
    meta: { title: '数据概况', code: EAuthCode.DATAANALYSIS_DATAOVERVIEW }
  },
  {
    path: routeNames.dataAnalysisBehaviorals,
    component: () => import('~/pages/dataAnalysis/behaviorals'),
    meta: { title: '行为分析', code: EAuthCode.DATAANALYSIS_BEHAVIORALS }
  },
  {
    path: routeNames.dataBasics,
    component: () => import('~/pages/dataAnalysis/dataBasics'),
    meta: { title: '基础数据统计', code: EAuthCode.DATAANALYSIS_DATABASICS }
  }
]

export default routes

import { TRoutes, routeNames } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

const routes: TRoutes[] = [
  {
    path: routeNames.marketingManageDetail,
    component: () => import('../../pages/marketingManage/detail'),
    meta: {
      title: '签到有礼',
      code: [EAuthCode.MARKETING_SIGNINGIFT]
    }
  },
  {
    path: routeNames.marketingManageEdit,
    component: () => import('../../pages/marketingManage/edit'),
    meta: {
      title: '编辑签到规则',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_SIGNINGIFT_UPDATE]
    }
  },
  {
    path: routeNames.discountSaleList,
    component: () => import('../../pages/marketingManage/discountSaleList'),
    meta: {
      title: '满减满折列表',
      code: EAuthCode.MARKETING_DISCOUNTSALE
    }
  },
  {
    path: routeNames.discountSaleDetail,
    component: () => import('../../pages/marketingManage/discountSaleDetail'),
    meta: {
      title: '编辑满减满折',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_DISCOUNTSALE_ADD, EAuthCode.MARKETING_DISCOUNTSALE_UPDATE]
    }
  },
  {
    path: routeNames.discountSaleInfo,
    component: () => import('../../pages/marketingManage/discountSaleInfo'),
    meta: {
      title: '满减满折活动详情',
      hideInMenu: true,
      code: EAuthCode.MARKETING_DISCOUNTSALE_DETAIL
    }
  },
  {
    path: routeNames.discountSaleCheck,
    component: () => import('../../pages/marketingManage/discountSaleCheck'),
    meta: {
      title: '满减满折审核',
      code: [EAuthCode.MARKETING_DISCOUNTSALECHECK]
    }
  },
  {
    path: routeNames.discountSaleCheckDetail,
    component: () => import('../../pages/marketingManage/discountSaleCheckDetail'),
    meta: {
      title: '满减满折审核详情',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_DISCOUNTSALECHECK_AUDIT, EAuthCode.MARKETING_DISCOUNTSALECHECK_DETAIL]
    }
  },
  {
    path: routeNames.discountSaleStore,
    component: () => import('../../pages/marketingManage/discountSaleStore'),
    meta: {
      title: '满减满折活动参与店铺列表',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_DISCOUNTSALE_STORELIST]
    }
  },
  {
    path: routeNames.discountSaleStoreGoods,
    component: () => import('../../pages/marketingManage/discountSaleStoreGoods'),
    meta: {
      title: '满减满折活动店铺商品列表',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_DISCOUNTSALE_STORELIST_GOODSLIST]
    }
  },
  {
    path: routeNames.timeBuyList,
    component: () => import('../../pages/marketingManage/timeBuyList'),
    meta: {
      title: '限时抢购列表',
      code: [EAuthCode.MARKETING_TIMEBUY]
    }
  },
  {
    path: routeNames.timeBuyDetail,
    component: () => import('../../pages/marketingManage/timeBuyDetail'),
    meta: {
      title: '编辑限时抢购',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_TIMEBUY_ADD, EAuthCode.MARKETING_TIMEBUY_UPDATE]
    }
  },
  {
    path: routeNames.timeBuyInfo,
    component: () => import('../../pages/marketingManage/timeBuyInfo'),
    meta: {
      title: '限时抢购活动详情',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_TIMEBUY_DETAIL]
    }
  },
  {
    path: routeNames.timeBuyCheck,
    component: () => import('../../pages/marketingManage/timeBuyCheck'),
    meta: {
      title: '限时抢购审核',
      code: [EAuthCode.MARKETING_DISCOUNTSALECHECK]
    }
  },
  {
    path: routeNames.timeBuyCheckDetail,
    component: () => import('../../pages/marketingManage/timeBuyCheckDetail'),
    meta: {
      title: '限时抢购审核详情',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_DISCOUNTSALECHECK_AUDIT, EAuthCode.MARKETING_DISCOUNTSALECHECK_DETAIL]
    }
  },
  {
    path: routeNames.timeBuyStore,
    component: () => import('../../pages/marketingManage/timeBuyStore'),
    meta: {
      title: '限时抢购活动参与店铺列表',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_TIMEBUY_STORELIST]
    }
  },
  {
    path: routeNames.timeBuyStoreGoods,
    component: () => import('../../pages/marketingManage/timeBuyStoreGoods'),
    meta: {
      title: '限时抢购活动店铺商品列表',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_TIMEBUY_STORELIST_GOODSLIST]
    }
  }
]

export default routes

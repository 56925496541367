import { createBrowserHistory } from 'history';
import { generateRouteAndMenu, IRoute, routeNames } from './const';
import BasicLayout from '~/layouts/basicLayout';
import MMPNoAuth from '~/pages/403';
import Login from '../pages/login'

import _example from './modules/_example';
import departmentManagement from './modules/departmentManagement';
import businessManagement from './modules/businessManagement'
import couponManagement from './modules/couponManagement'
import categoryStorage from './modules/categoryStorage'
import financialManagement from './modules/financialManagement'
import goodsLibrary from './modules/goodsLibrary'
import decorationSetting from './modules/decorationSetting'
import management from './modules/management'
import riskEarlyWarning from './modules/riskEarlyWarning'
import basicSetting from './modules/basicSetting'
import marketingManage from './modules/marketingManage'
import orderManage from './modules/orderManagement'
import memberManage from './modules/memberManage'
import servicer from './modules/servicer'
import ministore from './modules/ministore'
import ChangePassword from '../pages/independentStore/changePassword'
import dataAnalysis from './modules/dataAnalysis'
import { AccountBookOutlined, AppstoreOutlined, BgColorsOutlined, ClusterOutlined, CrownOutlined, NotificationOutlined, ProfileOutlined, SettingOutlined, ShopOutlined, TeamOutlined, UnorderedListOutlined, TagsOutlined, MessageOutlined, BarChartOutlined, WechatOutlined } from '@ant-design/icons';

export const history = createBrowserHistory();

export const routers: IRoute[] = [
  {
    path: routeNames.login,
    component: Login,
    meta: { title: '登录', sync: true, noLogin: true }
  },
  {
    path: routeNames.changePassword,
    component: ChangePassword,
    meta: { title: '修改密码', sync: true }
  },
  {
    path: routeNames[403],
    component: MMPNoAuth,
    meta: { title: '没有权限', sync: true, noLogin: true }
  },
  // {
  //   path: routeNames.servicerCustomerInfo,
  //   component: () => import('../pages/servicer/customerInfo'),
  //   meta: { title: '客服-用户信息', noLogin: true }
  // },
  {
    path: '/',
    layout: BasicLayout,
    redirect: true,
    children: [
      { name: '模板', icon: UnorderedListOutlined, children: _example },
      { name: '基础设置', icon: SettingOutlined, children: basicSetting },
      { name: '类目库', icon: ClusterOutlined, children: categoryStorage },
      { name: '商品库', icon: AppstoreOutlined, children: goodsLibrary },
      { name: '商家管理', icon: ShopOutlined, children: businessManagement },
      { name: '优惠券', icon: TagsOutlined, children: couponManagement },
      { name: '财务管理', icon: AccountBookOutlined, children: financialManagement },
      { name: '权限管理', icon: TeamOutlined, children: departmentManagement },
      { name: '会员管理', icon: CrownOutlined, children: memberManage },
      { name: '订单管理', icon: ProfileOutlined, children: orderManage },
      { name: '营销管理', icon: NotificationOutlined, children: marketingManage },
      { name: '装修设置', icon: BgColorsOutlined, children: decorationSetting },
      { name: '风险预警', icon: BgColorsOutlined, children: riskEarlyWarning },
      { name: '直播管理', icon: BgColorsOutlined, children: management },
      // { name: '客服管理', icon: MessageOutlined, children: servicer },
      { name: '微信视频号', icon: WechatOutlined, children: ministore },
      { name: '数据仪表', icon: BarChartOutlined, children: dataAnalysis },

      { path: routeNames[403], component: MMPNoAuth, meta: { sync: true, noLogin: true } }
    ]
  }
]

const { routes } = generateRouteAndMenu(routers)

export function getMenus() {
  return generateRouteAndMenu(routers).menus;
}

export { routes }

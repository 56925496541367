import styles from './index.module.less'
import { FC, memo, useContext, useState } from 'react'
import { Layout } from 'antd'
import SideMenus from '../sideMenus'
import UserInfo from './userInfo'
import logo from '~/assets/images/logo_320_120.png'
import logoSmall from '~/assets/images/logo_64_64.png'
import classNames from 'classnames'
import globalContext from '~/contexts/global.context'

const { Header, Sider, Content } = Layout

const Component: FC<{ children?: any }> = (props) => {
  const [collapsed, setcollapsed] = useState(false)
  const { layoutSetting } = useContext(globalContext)

  const onCollapse = (collapsed: boolean) => {
    setcollapsed(collapsed)
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider theme={layoutSetting.menuTheme} collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className={styles.logWrapper}>{collapsed ? <img src={logoSmall} className={styles.logo_sm} /> : <img src={logo} className={styles.logo} />}</div>
        <SideMenus />
      </Sider>
      <Layout>
        <Header className={classNames(styles.header, layoutSetting.headerTheme === 'dark' ? styles.header_dark : styles.header_light)}>
          <div className={styles.header_left} />
          <div className={styles.header_right}>
            <UserInfo />
          </div>
        </Header>
        <div className={styles.content}>{props.children}</div>
        {/* <Footer style={{ textAlign: 'center' }}> Design ©2018 Created by Ant UED</Footer> */}
      </Layout>
    </Layout>
  )
}

Component.displayName = 'BasicLayout'

const BasicLayout = memo(Component)
export default BasicLayout

import { EAuthCode } from '~/enums/EAuthCode'
import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  {
    path: routeNames.departmentManagement,
    component: () => import('../../pages/departmentManagement'),
    meta: {
      title: '部门管理',
      code: EAuthCode.PERMISSIONS_DEPT
    }
  },
  {
    path: routeNames.employeeManagement,
    component: () => import('../../pages/departmentManagement/employeeManagement'),
    meta: {
      title: '员工管理',
      code: EAuthCode.PERMISSIONS_STAFF
    }
  },
  {
    path: routeNames.roleManagement,
    component: () => import('../../pages/departmentManagement/roleManagement'),
    meta: {
      title: '角色管理',
      code: EAuthCode.PERMISSIONS_ROLE
    }
  },
  {
    path: routeNames.companyResourcesGroup,
    component: () => import('../../pages/departmentManagement/companyResourcesGroup'),
    meta: {
      title: '企业资源分组',
      code: EAuthCode.PERMISSIONS_COMPANYRESOURCESGROUP
    }
  },
  {
    path: routeNames.companyResourcesGroupEdit,
    component: () => import('../../pages/departmentManagement/companyResourcesGroup/edit'),
    meta: {
      title: '企业资源分组',
      hideInMenu: true,
      code: [EAuthCode.PERMISSIONS_COMPANYRESOURCESGROUP_ADD, EAuthCode.PERMISSIONS_COMPANYRESOURCESGROUP_UPDATE]
    }
  },
  {
    path: routeNames.logRecord,
    component: () => import('../../pages/departmentManagement/logRecord'),
    meta: {
      title: '操作日志记录',
      code: [EAuthCode.PERMISSIONS_LOGRECORD]
    }
  }
] as TRoutes[]

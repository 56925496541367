import { PureComponent } from 'react'
import { IErrorBoundaryProps, IErrorBoundaryState } from './const'
import { Button, message, Result } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'

class ErrorBoundary extends PureComponent<IErrorBoundaryProps & RouteComponentProps, IErrorBoundaryState> {
  static defaultProps: IErrorBoundaryProps = {}

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true }
  }

  state: IErrorBoundaryState = {
    hasError: false
  }

  componentDidCatch(error, errorInfo) {
    // 你同样可以将错误日志上报给服务器
    // logErrorToMyService(error, errorInfo);
    // 当出现资源加载失败时.自动刷新页面
    if (error.type === 'missing' || error.name === 'ChunkLoadError') {
      message.warn('检查到系统资源更新,将刷新页面')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
      return
    }
    console.log(error, errorInfo)
  }

  render() {
    return this.state.hasError ? this.renderError() : this.props.children
  }

  renderError() {
    return (
      <Result
        status="500"
        title="500"
        subTitle="抱歉,系统出现异常"
        extra={
          <Button
            type="primary"
            onClick={() => {
              window.location.reload()
            }}
          >
            重试
          </Button>
        }
      />
    )
  }
}

export default withRouter(ErrorBoundary)

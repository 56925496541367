import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.basicSetting,
    component: () => import('../../pages/basicSetting/basicSetting'),
    meta: { title: '基础设置', code: EAuthCode.SETTING_BASE }
  },
  {
    path: routeNames.homeIndex,
    component: () => import('../../pages/basicSetting/homeIndex'),
    meta: { title: '页面首页', hideInMenu: true }
  },
  {
    path: routeNames.channelMaintain,
    component: () => import('../../pages/basicSetting/channelMaintain'),
    meta: { title: '渠道维护', code: EAuthCode.SETTING_CHANNELMAINTAIN }
  },
  {
    path: routeNames.label,
    component: () => import('../../pages/basicSetting/label'),
    meta: { title: '投放标签维护', code: EAuthCode.SETTING_LABEL }
  },
  {
    path: routeNames.link,
    component: () => import('../../pages/basicSetting/link'),
    meta: { title: '投放链接维护', code: EAuthCode.SETTING_LINK }
  },
  {
    path: routeNames.defaultSearch,
    component: () => import('../../pages/basicSetting/defaultSearch'),
    meta: { title: '默认搜索词', code: EAuthCode.SETTING_DEFAULTKEY }
  },
  {
    path: routeNames.hotSearch,
    component: () => import('../../pages/basicSetting/hotSearch'),
    meta: { title: '热门搜索词', code: EAuthCode.SETTING_HOTKEY }
  },
  {
    path: routeNames.advertisingSpace,
    component: () => import('../../pages/basicSetting/advertisingSpace'),
    meta: { title: '广告位', code: EAuthCode.SETTING_ADVERTISINGSPACE }
  },
  {
    path: routeNames.advertisingSpaceAdd,
    component: () => import('../../pages/basicSetting/advertisingSpace/add'),
    meta: { title: '广告位新增', code: [EAuthCode.SETTING_ADVERTISINGSPACE_ADD, EAuthCode.SETTING_ADVERTISINGSPACEDETAIL_UPDATE], hideInMenu: true }
  },
  // {
  //   path: routeNames.platformTheme,
  //   component: () => import('../../pages/basicSetting/platformTheme'),
  //   meta: { title: '平台主题', code: EAuthCode.SETTING_PLATFORMTHEME }
  // },
  {
    path: routeNames.popUp,
    component: () => import('../../pages/basicSetting/popUp'),
    meta: { title: '首页弹框', code: EAuthCode.SETTING_POPUP }
  },
  {
    path: routeNames.shopBanner,
    component: () => import('../../pages/basicSetting/shopBanner'),
    meta: { title: '店铺轮播图', code: EAuthCode.SETTING_SHOPBANNER }
  },
  {
    path: routeNames.protocolManagement,
    component: () => import('../../pages/basicSetting/protocolManagement'),
    meta: { title: '协议管理', code: EAuthCode.SETTING_PROTOCOLMANAGEMENT }
  },
  {
    path: routeNames.helpCenterCategory,
    component: () => import('../../pages/basicSetting/helpCenterCategory'),
    meta: { title: '帮助中心分类', code: EAuthCode.SETTING_HELPCENTERCATEGORY }
  },
  {
    path: routeNames.helpCenterArticle,
    component: () => import('../../pages/basicSetting/helpCenterArticle'),
    meta: { title: '帮助中心文章', code: EAuthCode.SETTING_HELPCENTERARTICAL }
  },
  {
    path: routeNames.suggestion,
    component: () => import('../../pages/basicSetting/suggestion'),
    meta: { title: '意见反馈', code: EAuthCode.SETTING_FEEDBACK }
  },
  {
    path: routeNames.clientSecret,
    component: () => import('../../pages/basicSetting/clientSecret'),
    meta: { title: '应用管理', code: EAuthCode.SETTING_CLIENT }
  }
] as TRoutes[]
import { EAuthCode } from '~/enums/EAuthCode';
import { routeNames, TRoutes } from '~/routes/const';

export default [
  {
    path: routeNames.decorationManageList,
    component: () => import('../../pages/decorationSetting/decorationManage/list'),
    meta: {
      title: '装修页面管理',
      code: EAuthCode.DECORATION_LIST
    }
  },
  {
    path: routeNames.decorationManageEdit,
    component: () => import('../../pages/decorationSetting/decorationManage/edit'),
    meta: {
      title: '装修页面设置',
      hideInMenu: true,
      code: [EAuthCode.DECORATION_LIST_ADD, EAuthCode.DECORATION_LIST_COPY, EAuthCode.DECORATION_LIST_UPDATE]
    }
  },
  {
    path: routeNames.activityNavigation,
    component: () => import('../../pages/decorationSetting/activityNavigation'),
    meta: {
      title: '活动导航',
      code: [EAuthCode.DECORATION_ACTIVITYNAVIGATION]
    }
  },
  {
    path: routeNames.materialLibrary,
    component: () => import('../../pages/decorationSetting/materialLibrary'),
    meta: {
      title: '素材库',
      code: EAuthCode.DECORATION_MATERIALLIBRARY
    }
  }
] as TRoutes[]

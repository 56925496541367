import { FC, memo, useRef, useState } from 'react'
import { FormInstance, Card, Button, Image, Modal, message } from 'antd'
import CryptoJS from 'crypto-js'
import { RouteComponentProps } from 'react-router'
import FormPro from '~/modules/@wmeimob/backend-pro/src/components/formPro'
import { EFormProItem } from '~/modules/@wmeimob/backend-pro/src/enums/EFormProItem'
import useFormPro from '~/modules/@wmeimob/backend-pro/src/hooks/useFormPro'
import styles from './index.module.less'
import { api } from '~/request'
import images from '~/assets/images/suo.png'
import { useSuperLock } from '~/hooks/useSuperLock'

export interface IPageProps extends RouteComponentProps {}

const Component: FC<IPageProps> = (props) => {
  const { history } = props

  const [visible, setVisible] = useState(false)
  const formRef = useRef<FormInstance>(null)

  const { option: formPro } = useFormPro({
    items: [
      {
        label: '原密码',
        type: EFormProItem.Input,
        name: 'password',
        rules: [{ required: true, max: 20 }],
        componentProp: {
          placeholder: '请输入原密码',
          type: 'password'
        }
      },
      {
        label: '新的密码',
        type: EFormProItem.Input,
        name: 'newPassword',
        hasFeedback: true,
        rules: [{ required: true, max: 20, min: 6 }],
        componentProp: {
          placeholder: '请输入新的密码',
          type: 'password'
        }
      },
      {
        label: '再次输入',
        type: EFormProItem.Input,
        name: 'newPassword2',
        dependencies: ['password'],
        hasFeedback: true,
        rules: [
          { required: true, max: 20, min: 6 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('两次输入密码不一致'))
            }
          })
        ],
        componentProp: {
          placeholder: '请再次输入密码',
          type: 'password'
        }
      }
    ]
  })

  const [handleClick, handleLoading] = useSuperLock(async () => {
    if (formRef.current) {
      const values = await formRef.current.validateFields()
      const encNewPassword = CryptoJS.enc.Utf8.parse(values.newPassword)
      const encPassword = CryptoJS.enc.Utf8.parse(values.password)
      const key = CryptoJS.enc.Utf8.parse('sdidASE5F41S5Dm7')
      const newPassword = CryptoJS.AES.encrypt(encNewPassword, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString()
      const password = CryptoJS.AES.encrypt(encPassword, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString()

      await api['/admin/auth/resetPwd_PUT']({ newPassword, password })
      setVisible(false)
      message.success('密码修改成功，下次登录生效。')
      history.goBack()
    }
  })

  return (
    <Card className={styles.component}>
      <div className={styles.head}>您当前的帐号 ：{'手机号码'}</div>
      <div className={styles.text}>
        <Image className={styles.Image} src={images} />
        <div className={styles.div}>
          <h2>登录密码</h2>
          <p>建议您定期更换密码，设置安全性高的密码可以使帐号更安全</p>
        </div>
        <Button onClick={() => setVisible(true)}>修改</Button>
      </div>
      <div className={styles.footer}>
        <h2>安全服务提示</h2>
        <div>• 建议您安装杀毒软件，并定期更新操作系统等软件补丁，确保帐号及交易安全</div>
      </div>
      <Modal title="修改密码" confirmLoading={handleLoading} visible={visible} onOk={handleClick} onCancel={() => setVisible(false)} maskClosable={false}>
        <FormPro ref={formRef} {...formPro} />
      </Modal>
    </Card>
  )
}

Component.defaultProps = {}
Component.displayName = 'ChangePassword'

const ChangePassword = memo(Component)
export default ChangePassword

import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.categoryStorageManagement,
    component: () => import('../../pages/categoryStorage/categoryManagement'),
    meta: { 
      title: '类目管理',
      code: [EAuthCode.CLASSIFY_CLASSIFYMANAGE]
    }
  },
  {
    path: routeNames.categoryBrandLibrary,
    component: () => import('../../pages/categoryStorage/brandLibraryList'),
    meta: {
      title: '品牌库',
      code: [EAuthCode.CLASSIFY_BRANDLIBRARY]
    }
  },
  {
    path: routeNames.categoryBrandManagementDetail,
    component: () => import('../../pages/categoryStorage/brandManagementDetail'),
    meta: {
      title: '品牌详情',
      hideInMenu: true,
      code: [EAuthCode.CLASSIFY_BRNADMANAGE_DETAIL]
    }
  },
  {
    path: routeNames.categoryBrandManagement,
    component: () => import('../../pages/categoryStorage/brandManagementList'),
    meta: {
      title: '品牌管理',
      code: [EAuthCode.CLASSIFY_BRNADMANAGE]
    }
  },
  {
    path: routeNames.categoryBrandLibraryDetail,
    component: () => import('../../pages/categoryStorage/brandLibraryDetail'),
    meta: {
      title: '品牌管理详情',
      hideInMenu: true,
      code: [EAuthCode.CLASSIFY_BRANDLIBRARY_ADD, EAuthCode.CLASSIFY_BRANDLIBRARY_UPDATE]
    }
  },
  {
    path: routeNames.categoryBrandAffiliationDetail,
    component: () => import('../../pages/categoryStorage/brandAffiliationDetail'),
    meta: {
      title: '品牌归属设置',
      hideInMenu: true,
      code: [EAuthCode.CLASSIFY_BRANDATTRIBUTIONCLASSIFY_AFFILIATION, EAuthCode.CLASSIFY_BRANDATTRIBUTIONCLASSIFY_AFFILIATIONUPDATE]
    }
  },
  {
    path: routeNames.categoryBrandAffiliation,
    component: () => import('../../pages/categoryStorage/brandAffiliationList'),
    meta: {
      title: '品牌归属类目',
      code: [EAuthCode.CLASSIFY_BRANDATTRIBUTIONCLASSIFY]
    }
  },
  {
    path: routeNames.categoryBrandAuditDetail,
    component: () => import('../../pages/categoryStorage/brandAuditDetail'),
    meta: {
      title: '审核详情',
      hideInMenu: true,
      code: [EAuthCode.CLASSIFY_BRANDAUDIT_AUDIT]
    }
  },
  {
    path: routeNames.categoryBrandAudit,
    component: () => import('../../pages/categoryStorage/brandAuditList'),
    meta: {
      title: '品牌审核',
      code: [EAuthCode.CLASSIFY_BRANDAUDIT]
    }
  }
] as TRoutes[]

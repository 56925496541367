import { Form, FormProps } from 'antd'
import { FormInstance, useForm } from 'antd/lib/form/Form'
import { forwardRef, memo } from 'react'
import { IFormProProps } from './const'
// import styles from './index.module.less';
import FormProItem from './components/formProItem'

const Component = forwardRef<FormInstance, IFormProProps>((props, ref) => {
  const { items = [], formProp = {}, maxWidth = 996 } = props

  const localFormProp: FormProps = {
    labelCol: { span: 6 },
    ...formProp
  }

  const [form] = useForm()
  if (!localFormProp.form) {
    localFormProp.form = form
  }

  return (
    <Form ref={ref} {...localFormProp} style={{ maxWidth }}>
      {items.map((item, index) => (
        <FormProItem {...item} key={index} />
      ))}
    </Form>
  )
})
Component.displayName = 'FormPro'

const FormPro = memo(Component)
export default FormPro
